import { render, staticRenderFns } from "./deliveryRequest.vue?vue&type=template&id=5517129e&scoped=true"
import script from "./deliveryRequest.vue?vue&type=script&lang=js"
export * from "./deliveryRequest.vue?vue&type=script&lang=js"
import style0 from "./deliveryRequest.vue?vue&type=style&index=0&id=5517129e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5517129e",
  null
  
)

component.options.__file = "deliveryRequest.vue"
export default component.exports