<template>
  <div class="container bgblue">
    <div class="header">
      <el-row>
        <el-form :model="form" class="demo-form-inline" label-width="115px">
          <el-col :span="5">
            <el-form-item label="用户姓名：">
              <el-input
                v-model="form.userName"
                placeholder="请输入用户姓名"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="订单类型：" abel-width="100px">
              <el-select
                v-model="form.status"
                @change="changeStatus"
                placeholder="请选择订单类型"
                clearable
              >
                <el-option label="已入库" value="0"></el-option>
                <el-option label="未入库" value="1"></el-option>
                <el-option label="已出库" value="2"></el-option>
                <el-option label="未出库" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="5" >
            <el-form-item label="DMS单号：">
              <el-input
                v-model="form.orderCode"
                placeholder="请输入DMS单号"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="申请公文号：" label-width="100px">
              <el-input
                v-model="form.bankingNumber"
                placeholder="请输入申请公文号"
                clearable
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="5">
            <el-form-item label="发货仓库：">
              <el-select
                v-model="form.status"
                @change="changeStatus"
                placeholder="请选择发货仓库"
                clearable
              >
                <el-option label="已入库" value="0"></el-option>
                <el-option label="未入库" value="1"></el-option>
                <el-option label="已出库" value="2"></el-option>
                <el-option label="未出库" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="接单日期：">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                start-placeholder="开始"
                end-placeholder="结束"
                @change="changeDate"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="是否补发单：" >
              <el-select
                v-model="form.status"
                @change="changeStatus"
                placeholder="请选择是否补发单"
                clearable
              >
                <el-option label="已入库" value="0"></el-option>
                <el-option label="未入库" value="1"></el-option>
                <el-option label="已出库" value="2"></el-option>
                <el-option label="未出库" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="是否申请提货：" >
              <el-select
                v-model="form.status"
                @change="changeStatus"
                placeholder="请选择是否提货"
                clearable
              >
                <el-option label="已入库" value="0"></el-option>
                <el-option label="未入库" value="1"></el-option>
                <el-option label="已出库" value="2"></el-option>
                <el-option label="未出库" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="10px">
              <el-button type="primary" icon="el-icon-search" @click="onSubmit"
                >查询</el-button
              >
              <el-button icon="el-icon-refresh" @click="onReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="table">
      <div>
        <el-button type="primary">申请提货</el-button>
        <el-button type="primary">取消提货</el-button>
      </div>
      <el-table :data="tableData"  border 
      height="calc(100vh - 250px)"
      >
      

        <el-table-column type="selection" width="35"> </el-table-column>
        <el-table-column
          align="center"
          prop="bankingNumber"
          label="用户姓名"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderType"
          label="DMS订单号"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="productInfo"
          label="申请公文号"
        >
        </el-table-column>
        <el-table-column align="center" prop="combNum" label="套数" width="180">
        </el-table-column>
        <el-table-column
          align="center"
          prop="userName"
          label="订单类型"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="接单时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="代理商名称"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="是否申请提货"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="申请时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="发货仓库"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="是否扣减虚仓"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="是否换货补发"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="inOutTime"
          label="运输方式"
        >
        </el-table-column>
        <el-table-column align="center" label="状态" >
          <template v-slot="{ row }">
            <el-button v-if="row.status == 0" class="btn-green">已入库</el-button >
            <el-button  v-if="row.status == 1" class="btn-orange">未入库</el-button >
            <el-button  v-if="row.status == 2" class="btn-green">已出库</el-button >
            <el-button  v-if="row.status == 3" class="btn-orange">未出库</el-button >
          </template>
        </el-table-column>
        <el-table-column align="center" prop="date" label="操作"  fixed>
          <template v-slot="{ row }">
            <el-button type="primary" plain @click="handelDetail(row)"
              >明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.currPage"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totol"
        >
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      detailList: [], //详情表格
      datailObj: {}, //详情对象
      showDialog: false,
      form: {
        userName: "",
        orderCode: "",
        bankingNumber: "",
        status: null,
        beginTime: "",
        endTime: "",
        date: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      totol: 0,
    };
  },
  methods: {
    onSubmit() {
      this.params.currPage = 1;
      this.getData();
    },
    async getData() {
      // let condition = {
      //   ...this.form,
      // };
      // const {
      //   data: { data },
      // } = await this.$http.post("/storeRecordBind/list", {
      //   condition,
      //   ...this.params,
      // });
      // this.totol = data.count;
      // this.tableData = data.data;
    },
    // 查看明细
    async handelDetail(row) {
      // this.datailObj = row;
      // this.showDialog = true;
      // const {
      //   data: { data },
      // } = await this.$http.post("/storeRecordBind/queryList", {
      //   inOutId: row.id,
      // });
      // this.detailList = data;
    },
    //状态
    changeStatus(val) {
      this.form.status = val;
    },
    //日期
    changeDate(val) {
      if (val) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
    onReset() {
      this.getData();
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;

  ::v-deep .el-input__inner {
    width: 100% !important;
  }
  ::v-deep .el-select {
    width: 100% !important;
  }
::v-deep .el-table{
  margin-top: 10px !important;
}
::v-deep .el-table td {
      padding: 0.5px 0;
      box-sizing: border-box;
    }
    ::v-deep .el-form-item__label{
    font-size: 13px;
   

  }
  .header {
     height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 0px !important;
    padding: 10px;
    
    box-sizing: border-box;
     box-shadow: 0px 0px 10px #ccc;
  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
  }
  .page {
    width: 100%;
    text-align: end;
  }
  .dialog {

    ::v-deep .el-button {
      height: 35px !important;
    }
  }
}
// ::v-deep .el-button--primary {
//   background-color: #3d6de1 !important;
// }
::v-deep .el-dialog__body {
  padding: 0 0 30px  0!important;
}

  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #f59a23 !important;
}
</style>
